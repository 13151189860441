import React from 'react';
import './App.css';
import { CountdownTimer } from './countdown/CookingTimer';

function App() {
  return (
    <div className="App">
      <div className="button-list">
        <CountdownTimer seconds={45} image="fisch.jpg" />
        <CountdownTimer seconds={65} image="huhn.jpg" />
        <CountdownTimer seconds={65} image="schwein.jpg" />
        <CountdownTimer seconds={65} image="schlange.jpg" />
        <CountdownTimer seconds={65} image="hai.jpg" />
        <CountdownTimer seconds={95} image="trophy-fisch.jpg" />
        <CountdownTimer seconds={120} image="megalodon.jpg" />
        <CountdownTimer seconds={120} image="kraken.jpg" />
      </div>
      <a href="https://bytemonkeys.de"><img className="byteMonkeys" alt="Developed by ByteMonkeys.de" src="bytemonkeys.svg"></img></a>
    </div>
  );
}

export default App;
