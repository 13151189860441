import Timer from 'react-compound-timer'
import './CookingTimer.css';
import React, { useState } from 'react';

type CountdownProps = {
    image: string,
    seconds: number
}

export function CountdownTimer(props: CountdownProps) {
    var [started, setStarted] = useState(false);
    const audio = new Audio();

    function playAudio() {
        audio.src = 'alarm.mp3';
        audio.play()
    }

    return (
        <Timer
            initialTime={props.seconds * 1000}
            startImmediately={false}
            direction={"backward"}
            timeToUpdate={100}
            checkpoints={[
                {
                    time: 5700,
                    callback: () => playAudio(),
                },
                {
                    time: 6,
                    callback: () => setStarted(false),
                },
            ]}
        >
            {({ start, resume, pause, stop, reset, timerState }) => (
                <React.Fragment>
                    <button className="wrapper" onClick={() => {
                        if (!started) {
                            audio.src = '';
                            audio.play();
                            reset();
                            start();
                            setStarted(true);
                        } else {
                            stop();
                            reset();
                            setStarted(false);
                        }
                    }}>
                        <img alt={props.image.slice(0, -4)} src={props.image} className="icon"/>
                        {started ?
                            <div className="timer">
                                <Timer.Minutes formatValue={value => value < 10 ? `0${value}` : `${value}`} />
                                :
                                <Timer.Seconds formatValue={value => value < 10 ? `0${value}` : `${value}`} />
                            </div>
                            : ''}
                    </button>
                </React.Fragment>
            )}
        </Timer>
    );
}

